
import { Options, Vue } from "vue-class-component";
import reCAPTCHA from "@/reCAPTCHA";

@Options({})
export default class Recovery extends Vue {
	step = -3; // ask to use reCAPTCHA
	nextStep = 1; // first step after reCAPTCHA confirmation
	notFound = false; // no accounts found
	visible = false; // password is visible
	exposed = false; // password has been breached
	user = {
		email: "",
		name: "",
		pwd: "",
		pwd2: "",
	};

	emailToken = "";
	recaptcha_key = process.env.VUE_APP_RECAPTCHA;

	async mounted (): Promise<void> {
		let token: string = document.location.hash.slice(1) as string;

		if (Reflect.has(this.$route.params, "emailToken")) {
			token = this.$route.params.emailToken as string;
		}

		if (token.startsWith("/")) {
			token = token.slice(1);
		}

		if (token.length > 1) {
			if (/^[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}$/i.test(token)) {
				this.emailToken = token;
				this.nextStep = 4; // with token: enter username to reset
			} else {
				this.step = -2; // invalid token
				return;
			}
		}

		// already loaded (user returned to this page)
		if (reCAPTCHA.isReady) {
			if (this.step == -3) {
				this.step = this.nextStep;
			}

			await this.$nextTick();
			reCAPTCHA.instance.render("recaptcha-container", {
				sitekey: process.env.VUE_APP_RECAPTCHA,
				size: "invisible",
			});
			reCAPTCHA.instance.reset();

			if (this.step == 1) {
				(this.$refs._email as HTMLInputElement).focus();
			} else if (this.step == 4) {
				(this.$refs._user as HTMLInputElement).focus();
			}
		}
	}

	async start (): Promise<void> {
		this.step = -4;

		try {
			await reCAPTCHA.load();
			this.step = this.nextStep;
			await this.$nextTick();

			if (this.step == 1) {
				(this.$refs._email as HTMLInputElement).focus();
			} else if (this.step == 4) {
				(this.$refs._user as HTMLInputElement).focus();
			}
		} catch (err) {
			this.step = -1
		}
	}

	async checkEmail (): Promise<void> {
		this.step = reCAPTCHA.isReady ? 2 : -1;
		// XXX: any actual checks needed here?
	}

	private sleep (milliseconds: number): Promise<number> {
		return new Promise(resolve => setTimeout(resolve, milliseconds));
	}

	async confirm (): Promise<void> {
		reCAPTCHA.instance.execute();
		let token = "";

		// the recaptcha API doesn't play nice with Vue
		while (!(token = reCAPTCHA.instance.getResponse())) {
			await this.sleep(1000);
		}

		const req = new Request(`${process.env.VUE_APP_API}/tmwa/account`, {
			method: "PUT",
			mode: "cors",
			cache: "no-cache",
			redirect: "follow",
			referrer: "no-referrer",
			headers: {
				"Accept": "application/json",
				"Content-Type": "application/json",
				"X-CAPTCHA-TOKEN": token,
			},
			body: JSON.stringify({
				email: this.user.email,
			}),
		});

		const rawResponse = await fetch(req);
		//const response: string = await rawResponse.text();

		switch (rawResponse.status) {
			// TODO: don't use alerts: embed the error message on the page
			case 200:
			case 201:
				this.step = 3;
				break;
			case 400:
				self.alert("API: malformed request");
				document.location.reload();
				break;
			case 403:
				self.alert("Captcha validation failed.\nPlease try again later");
				document.location.reload();
				break;
			case 404:
				this.notFound = true;
				this.step = 1;
				reCAPTCHA.instance.reset();
				await this.$nextTick();
				(this.$refs._email as HTMLInputElement).focus();
				break;
			case 408:
				this.step = -2;
				break;
			case 425:
				self.alert("An email has already been sent.\nPlease check your inbox and wait before you try again.");
				document.location.reload();
				break;
			case 429:
				self.alert("Too many requests.\nPlease try again later");
				document.location.reload();
				break;
			case 500:
				self.alert("Internal server error.\nPlease try again later");
				document.location.reload();
				break;
			case 502:
				self.alert("Couldn't reach the server.\nPlease try again later");
				document.location.reload();
				break;
			default:
				self.alert(`Unknown error: ${rawResponse.status}`);
				document.location.reload();
				break;
		}
	}

	async checkUser (): Promise<void> {
		// TODO: check if the token is valid for this username
		this.step = reCAPTCHA.isReady ? 5 : -1;
		await this.$nextTick();
		(this.$refs._password as HTMLInputElement).focus();
	}

	// TODO: this is not compatible with Edge! we must polyfill
	private async sha1 (text: string) {
		const encoder = new TextEncoder();
		const data = encoder.encode(text);
		const buffer = await self.crypto.subtle.digest("SHA-1", data);
		return this.hexString(buffer);
	}

	// turns a subtlecrypto arraybuffer into a usable hex string
	private hexString (buffer: ArrayBuffer) {
		const byteArray = new Uint8Array(buffer);
		const hexCodes = Array.from(byteArray).map(value =>
			value.toString(16).padStart(2, "0"));

		return hexCodes.join("");
	}

	async checkPassword (): Promise<void> {
		const fullHash = await this.sha1(this.user.pwd);
		const hashPrefix = fullHash.substring(0, 5);
		const hashSuffix = fullHash.substring(5);

		const req = new Request(`https://api.pwnedpasswords.com/range/${hashPrefix}`, {
			mode: "cors",
			cache: "force-cache",
			referrer: "no-referrer",
		});

		const rawResponse = await fetch(req);
		const response: string = await rawResponse.text();

		const found = response.split("\n").some(h => {
			const [hs,] = h.split(":");
			return hashSuffix.toUpperCase() === hs.toUpperCase();
		});

		if (found) {
			// reset the animation
			if (this.exposed) {
				this.exposed = false;
				await this.$nextTick();
			}

			this.exposed = true;
			await this.$nextTick();
			(this.$refs._password as HTMLInputElement).focus();
		} else {
			this.exposed = false;
			this.step = 6;
		}
	}

	async confirm2 (): Promise<void> {
		reCAPTCHA.instance.execute();
		let token = "";

		// the recaptcha API doesn't play nice with Vue
		while (!(token = reCAPTCHA.instance.getResponse())) {
			await this.sleep(1000);
		}

		const req = new Request(`${process.env.VUE_APP_API}/tmwa/account`, {
			method: "PUT",
			mode: "cors",
			cache: "no-cache",
			redirect: "follow",
			referrer: "no-referrer",
			headers: {
				"Accept": "application/json",
				"Content-Type": "application/json",
				"X-CAPTCHA-TOKEN": token,
			},
			body: JSON.stringify({
				username: this.user.name,
				password: this.user.pwd,
				code: this.emailToken,
			}),
		});

		const rawResponse = await fetch(req);
		//const response: string = await rawResponse.text();

		switch (rawResponse.status) {
			// TODO: don't use alerts: embed the error message on the page
			case 200:
			case 201:
				this.step = 7;
				break;
			case 400:
				self.alert("API: malformed request");
				document.location.reload();
				break;
			case 403:
				self.alert("Captcha validation failed.\nPlease try again later");
				document.location.reload();
				break;
			case 404:
				self.alert("You are unauthorized to reset the password of this account.\nOnly accounts listed in the email you received can be reset.");
				this.$router.replace({ name: "support" });
				break;
			case 408:
				this.step = -2;
				break;
			case 429:
				self.alert("Too many requests.\nPlease try again later");
				document.location.reload();
				break;
			case 500:
				self.alert("Internal server error.\nPlease try again later");
				document.location.reload();
				break;
			case 502:
				self.alert("Couldn't reach the server.\nPlease try again later");
				document.location.reload();
				break;
			default:
				self.alert(`Unknown error: ${rawResponse.status}`);
				document.location.reload();
				break;
		}
	}
}
